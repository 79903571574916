export const breadcrumbMap = {
    'crm': 'Home',
    'crm-certificates': 'Certificaat overzicht',
    'crm-certificates-add': 'Certificaat toevoegen',
    'crm-certificates-add-relationId': 'Certificaat toevoegen',
    'crm-certificates-edit-id': 'Certificaat: …',
    'crm-certificates-expiring-this-year': 'Aflopende certificaten',
    'crm-certificates-prolongate': 'Certificaten verlengen',
    'crm-certificates-view-id': 'Certificaat: …',
    'crm-chambers': 'Kamers',
    'crm-chambers-add': 'Kamer toevoegen',
    'crm-chambers-edit-id': 'Kamer bewerken',
    'crm-companies': 'Bedrijvenoverzicht',
    'crm-companies-add': 'Bedrijf toevoegen',
    'crm-companies-edit-id': 'Bedrijf: …',
    'crm-companies-view-id': 'Bedrijf: …',
    'crm-courses': 'Cursusoverzicht',
    'crm-courses-edit-id': 'Cursus: …',
    'crm-courses-view-id': 'Cursus: …',
    'crm-dashboard': 'Dashboard',
    'crm-documents': 'Documenten',
    'crm-documents-add': 'Document toevoegen',
    'crm-documents-edit-id': 'Document bewerken',
    'crm-exact': 'Exact',
    'crm-extranet-notifications': 'Notificaties',
    'crm-extranet-notifications-add': 'Notificatie toevoegen',
    'crm-extranet-notifications-edit-id': 'Notificatie bewerken',
    'crm-invoices': 'Facturen overzicht',
    'crm-invoices-add': 'Factuur toevoegen',
    'crm-invoices-add-relation': 'Factuur toevoegen',
    'crm-invoices-annual': 'Maak jaarlijkse facturen',
    'crm-invoices-edit-id': 'Bewerk factuur',
    'crm-invoices-exact-export': 'Exact export',
    'crm-invoices-exact-import': 'Exact import',
    'crm-invoices-send-status': 'Verstuur … facturen',
    'crm-invoices-status': '…',
    'crm-invoices-view-id': 'Factuur: …',
    'crm-level-of-measurements-status-texts': 'Statusoverzicht',
    'crm-level-of-measurements-status-texts-edit-id': 'Bewerk status',
    'crm-level-of-measurements-type': 'Niveaumetingen',
    'crm-level-of-measurements-view-id-type': 'Koppel relatie',
    'crm-login': 'Login',
    'crm-login-forgot-password': 'Reset wachtwoord',
    'crm-login-forgot-password-reset-token': 'Reset wachtwoord',
    'crm-mail-messages': 'Emailberichten',
    'crm-mail-messages-edit-id': 'Emailbericht bewerken',
    'crm-products': 'Factuurcodes',
    'crm-products-add': 'Factuurcode toevoegen',
    'crm-products-edit-id': 'Factuurcode bewerken',
    'crm-registration-types': 'Registratie types',
    'crm-registration-types-add': 'Registratie type toevoegen',
    'crm-registration-types-edit-id': 'Registratie type bewerken',
    'crm-registrations': 'Registraties',
    'crm-relations': 'Relatie overzicht',
    'crm-relations-add': 'Relatie toevoegen',
    'crm-relations-add-course-id': 'Relatie …',
    'crm-relations-edit-id': 'Relatie: …',
    'crm-relations-view-id': 'Relatie: …',
    'crm-relations-view-id-level-measurements-levelMeasurementId': 'Relatie: …',
    'crm-reports': 'Rapporten overzicht',
    'crm-reports-create': 'Rapport toevoegen',
    'crm-signups': 'Inschrijvingen',
    'crm-signups-edit-id': 'Inschrijving: …',
    'crm-signups-view-id': 'Inschrijving: …',
    'crm-subscriptions-add': 'Certificaat toevoegen',
    'crm-subscriptions-add-relationId': 'Certificaat toevoegen',
    'crm-subscriptions-edit-subscriptionId': 'Certificaat: …',
    'crm-subscriptions-view-subscriptionId': 'Certificaat: …',
    'crm-tax-rates': 'BTW tarieven',
    'crm-tax-rates-add': 'BTW tarief toevoegen',
    'crm-tax-rates-edit-id': 'BTW tarief bewerken',
    'crm-users': 'Gebruikersoverzicht',
    'crm-users-add': 'Gebruiker toevoegen',
    'crm-users-edit-id': 'Gebruiker bewerken',
    'extranet': 'extranet',
    'extranet-login': 'extranet-login',
    'extranet-login-as': 'extranet-login-as',
    'extranet-login-forgot-password': 'extranet-login-forgot-password',
    'extranet-login-forgot-password-reset-token': 'extranet-login-forgot-password-reset-token',
    'extranet-profile-certificate-id': 'extranet-profile-certificate-id',
    'extranet-profile-edit': 'extranet-profile-edit',
    'extranet-profile-edit-change-password': 'extranet-profile-edit-change-password',
    'index': 'index',
};
