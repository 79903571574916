import { type FetchError } from 'ofetch';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type {
    CertificateResponse,
    CertificatesOverview,
    CertificatesOverviewResponse,
    ReadCertificateRegistrationPointsDto,
    ReadCertificateRegistrationPointsResponse,
    WriteCertificateDto,
    WriteCertificateScoresLevelOneDto,
    WriteEarlyProlongateCertificateDto,
} from '~/types/Certificate';
import { CertificateFactory } from '~/models/factories/CertificateFactory';
import type { Certificate } from '~/models/Certificate';
import type { PointResponse, ReadEssaysDto, ReadPointsDto } from '~/types/Points';
import { CertificateProlongateFactory } from '~/models/factories/CertificateProlongateFactory';
import type { CertificateProlongate } from '~/models/CertificateProlongate';
import type { CertificatesProlongateResponse, WriteCertificatesProlongateDto } from '~/types/CertificateProlongate';
import type { RegistrationsOverview, RegistrationsOverviewResponse } from '~/types/Registration';
import { RegistrationFactory } from '~/models/factories/RegistrationFactory';

export default class CertificateService extends BaseApiService {
    public static basePath = 'certificates';

    /**
     * @description Extend a certificate.
     * @param {number} certificateId Identifier of the certificate to extend
     * @returns {Promise<null>} Api response
     */
    createExtension(certificateId: number): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/createExtension`,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Extend a certificate early.
     * @param {number} certificateId Identifier of the certificate to extend
     * @param {WriteEarlyProlongateCertificateDto} data Date the certificate should be prolonged from
     * @returns {Promise<null>} Api response
     */
    createEarlyExtension(certificateId: number, data: WriteEarlyProlongateCertificateDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/createEarlyExtension`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch single certificate by certificate id.
     * @param {number} certificateId Identifier of the certificate to fetch
     * @returns {Promise<Certificate>} Promise with the Certificate model as payload
     */
    async fetchCertificate(certificateId: number): Promise<Certificate> {
        try {
            const response = await this.baseGet<CertificateResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}`,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchCertificates(queryParameters: (Record<string, unknown> | null) = null): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                this.createPath(CertificateService.basePath),
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates eligible for extension depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchCertificatesEligibleForExtension(
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/eligible-for-extension`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates eligible for extension depending on values in queryString.
     * @returns {Promise<CertificateProlongate[]>} Promise with the Certificates as payload
     */
    async fetchCertificatesEligibleForProlongation(): Promise<CertificateProlongate[]> {
        try {
            const response = await this.baseGet<CertificatesProlongateResponse>(
                `${this.createPath(CertificateService.basePath)}/eligible-for-prolongation`,
            );

            return (new CertificateProlongateFactory()).toModels(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Prolongate certificates.
     * @param {number} data List of all certicates to prolongate
     * @returns {Promise<null>} Api response
     */
    prolongateCertificates(data: WriteCertificatesProlongateDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/prolongate`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple certificates depending on the parsed certificateId and the values in queryString.
     * @param {number} certificateId ID of the certificate to fetch other, to the same relation linked, certificates of
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<CertificatesOverview>} Promise with the CertificatesOverview as payload
     */
    async fetchOtherCertificates(
        certificateId: number,
        queryParameters: (Record<string, unknown> | null) = null,
    ): Promise<CertificatesOverview> {
        try {
            const response = await this.baseGet<CertificatesOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/otherCertificates`,
                queryParameters,
            );

            const data = (new CertificateFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch points of a certificate by certificate id.
     * @param {number} certificateId Identifier of the certificate to fetch the points of
     * @returns {Promise<ReadEssaysDto | ReadPointsDto>} Promise with the ReadEssaysDto or ReadPointsDtoas payload
     */
    async fetchPointsById(certificateId: number): Promise<ReadEssaysDto | ReadPointsDto> {
        try {
            const response = await this.baseGet<PointResponse>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/points`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Fetch multiple registrations depending on values in queryString.
     * @param {Array} queryParameters Array of GET Parameters
     * @returns {Promise<RegistrationsOverview>} Promise with the RegistrationsOverview as payload
     */
    async fetchRegistrations(queryParameters: (Record<string, unknown> | null) = null): Promise<RegistrationsOverview> {
        try {
            const response = await this.baseGet<RegistrationsOverviewResponse>(
                `${this.createPath(CertificateService.basePath)}/registrations`,
                queryParameters,
            );

            const data = (new RegistrationFactory()).toModels(response.data);
            const { meta } = response;

            return {
                data,
                meta,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Create a new certificate.
     * @param {WriteCertificateDto} data Form data to post
     * @returns {Promise<Certificate>} Api response
     */
    async create(data: WriteCertificateDto): Promise<Certificate> {
        try {
            const response = await this.basePost<CertificateResponse>(
                this.createPath(CertificateService.basePath),
                data,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a certificate.
     * @param {number} certificateId Id of the resource to save
     * @param {WriteCertificateDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    update(certificateId: number, data: WriteCertificateDto): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Delete a certificate.
     * @param {number} certificateId Id of the resource to delete
     * @returns {Promise<null>} Api response
     */
    delete(certificateId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(CertificateService.basePath)}/${certificateId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    /**
     * @description Update a certificate.
     * @param {number} certificateId Id of the resource to save
     * @param {WriteCertificateScoresLevelOneDto} data Form data to post
     * @returns {Promise<null>} Api response
     */
    updateScores(certificateId: number, data: WriteCertificateScoresLevelOneDto): Promise<null> {
        try {
            return this.basePost<null>(
                `${this.createPath(CertificateService.basePath)}/${certificateId}/level-measurements`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchRegistrationPoints(): Promise<ReadCertificateRegistrationPointsDto[]> {
        try {
            const response = await this.baseGet<ReadCertificateRegistrationPointsResponse>(
                `${this.createPath(CertificateService.basePath)}/registrations/points`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
