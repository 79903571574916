import { default as indexZWT5guxd9lMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/brandguide/index.vue?macro=true";
import { default as _91relationId_93mch4nD2dcTMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/add/[relationId].vue?macro=true";
import { default as index48kfoKFxXcMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/add/index.vue?macro=true";
import { default as _91id_934o4zXCgSZoMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/edit/[id].vue?macro=true";
import { default as indexoyXbQsvhO6Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/expiring-this-year/index.vue?macro=true";
import { default as indexkmeWoVPDaMMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/index.vue?macro=true";
import { default as indexzcbzLSFYVJMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/prolongate/index.vue?macro=true";
import { default as _91id_93Es0sEcra5PMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/view/[id].vue?macro=true";
import { default as indexnhscMWKI2AMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/add/index.vue?macro=true";
import { default as _91id_93bdPtxjnIrSMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/edit/[id].vue?macro=true";
import { default as indexkmBkBKdnbtMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/index.vue?macro=true";
import { default as indexFCGSgHWNERMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/add/index.vue?macro=true";
import { default as _91id_93rEsRgGfzu7Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/edit/[id].vue?macro=true";
import { default as indexVU2k53z6inMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/index.vue?macro=true";
import { default as _91id_93hrJcF4z1zcMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/view/[id].vue?macro=true";
import { default as _91id_931ukLx50lrWMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/edit/[id].vue?macro=true";
import { default as indexLNsgFIZEH8Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/index.vue?macro=true";
import { default as _91id_93ZTA4NrKmgdMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/view/[id].vue?macro=true";
import { default as index0zn8SFgkZUMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/dashboard/index.vue?macro=true";
import { default as indexCT81Fw0wZXMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/add/index.vue?macro=true";
import { default as _91id_9379Bce3s7z2Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/edit/[id].vue?macro=true";
import { default as indexqEt7cZHdWKMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/index.vue?macro=true";
import { default as indexuutEyE4BlUMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/exact/index.vue?macro=true";
import { default as indexKnoZAdhfUkMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/add/index.vue?macro=true";
import { default as _91id_93UZqjBWScgFMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/edit/[id].vue?macro=true";
import { default as indexuYdMVx0PgVMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/index.vue?macro=true";
import { default as indexn8hDdLg0PoMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/index.vue?macro=true";
import { default as _91status_93JwGuaFiyFoMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/[status].vue?macro=true";
import { default as _91relation_93uji7LxpzBXMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/add/[relation].vue?macro=true";
import { default as indexm9ge6HjeFfMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/add/index.vue?macro=true";
import { default as indexMb4k6KvCAeMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/annual/index.vue?macro=true";
import { default as _91id_930vghZBBMEsMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/edit/[id].vue?macro=true";
import { default as indexrQNlTh0qS0Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/index.vue?macro=true";
import { default as _91status_93GoZG5TnNbYMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/send/[status].vue?macro=true";
import { default as _91id_93ifvUNNPB5vMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/view/[id].vue?macro=true";
import { default as _91type_93QAcNrZs9X9Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/[type].vue?macro=true";
import { default as _91id_93d1bvJdgHohMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/status-texts/edit/[id].vue?macro=true";
import { default as index6BM5GApEPwMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/status-texts/index.vue?macro=true";
import { default as _91type_933P2hza203JMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/view/[id]/[type].vue?macro=true";
import { default as indexhMpTzqeN6hMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/forgot-password/index.vue?macro=true";
import { default as _91token_93hdTMI7YQcaMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/forgot-password/reset/[token].vue?macro=true";
import { default as indexeajxvNVXr9Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/index.vue?macro=true";
import { default as _91id_93koKpwxAPfEMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/mail-messages/edit/[id].vue?macro=true";
import { default as indexvNYJcz0SdCMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/mail-messages/index.vue?macro=true";
import { default as indexf5bucg1sx3Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/add/index.vue?macro=true";
import { default as _91id_93stQcPd9JN5Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/edit/[id].vue?macro=true";
import { default as indexdDzPEtEXLjMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/index.vue?macro=true";
import { default as indexYDBZWTgwg1Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/add/index.vue?macro=true";
import { default as _91id_93JZmPtQkfGtMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/edit/[id].vue?macro=true";
import { default as indexEV9liVIPU2Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/index.vue?macro=true";
import { default as indexkQrIcOecfAMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registrations/index.vue?macro=true";
import { default as _91id_93wtRuw8U8qLMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/add-course/[id].vue?macro=true";
import { default as indexEYL4TD5YrLMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/add/index.vue?macro=true";
import { default as _91id_93DHWuVEbnLsMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/edit/[id].vue?macro=true";
import { default as index1nwG20phuiMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/index.vue?macro=true";
import { default as indextsIgrh2ATnMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/view/[id]/index.vue?macro=true";
import { default as _91levelMeasurementId_934lv4V7mAA5Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/view/[id]/level-measurements/[levelMeasurementId].vue?macro=true";
import { default as indexepKP2x6CDEMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/reports/create/index.vue?macro=true";
import { default as indexONjnZLfwakMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/reports/index.vue?macro=true";
import { default as _91id_93XuOYFebkELMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/edit/[id].vue?macro=true";
import { default as indexn765xSOa3PMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/index.vue?macro=true";
import { default as _91id_93id3JbssjBIMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/view/[id].vue?macro=true";
import { default as _91relationId_93yTov4kTL7uMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/add/[relationId].vue?macro=true";
import { default as indexi3whM4F5hQMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/add/index.vue?macro=true";
import { default as _91subscriptionId_93KTisgdu4X9Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/edit/[subscriptionId].vue?macro=true";
import { default as _91subscriptionId_93SnctBhgc3YMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/view/[subscriptionId].vue?macro=true";
import { default as indexy8lGmqUYEdMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/add/index.vue?macro=true";
import { default as _91id_9372yGuBbADUMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/edit/[id].vue?macro=true";
import { default as indexzq1eXl510IMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/index.vue?macro=true";
import { default as indexluYsXIpK80Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/add/index.vue?macro=true";
import { default as _91id_93Wb2MrIXMMXMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/edit/[id].vue?macro=true";
import { default as indexx0gm6fyJ93Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/index.vue?macro=true";
import { default as indexqH4HVTJAqQMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/bedankt/index.vue?macro=true";
import { default as indexSZn4nozJA4Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/certificates/index.vue?macro=true";
import { default as indexKAlZ6NMdULMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/courses/index.vue?macro=true";
import { default as indexl2HiiLIAkPMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/documents/index.vue?macro=true";
import { default as indexuW90PlA3MjMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/further-training/index.vue?macro=true";
import { default as indexIhF7Gi0wvWMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/index.vue?macro=true";
import { default as bevestigingdXSsaTawYcMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/bevestiging.vue?macro=true";
import { default as formulierySCU0eAbyzMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/formulier.vue?macro=true";
import { default as indexfwzwj55ZxwMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/index.vue?macro=true";
import { default as indexAEz2y8MnRCMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/level-measurements/index.vue?macro=true";
import { default as _91_46_46_46slug_93nlh0KHqievMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/[...slug].vue?macro=true";
import { default as asuKbX6Q6NTKMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/as.vue?macro=true";
import { default as index6cGWjbFdmYMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/forgot-password/index.vue?macro=true";
import { default as _91token_93Otb2XpWusBMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/forgot-password/reset/[token].vue?macro=true";
import { default as indexZSvxhB6i3rMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/my-invoices/index.vue?macro=true";
import { default as indexVOR7zM4jGAMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/opleiding/index.vue?macro=true";
import { default as _91id_935jG18rDQatMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/certificate/[id].vue?macro=true";
import { default as indexXWCg5lcAsZMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/edit/change-password/index.vue?macro=true";
import { default as indexAEdHdVtXC3Meta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/edit/index.vue?macro=true";
import { default as indexBCpgHFaMNWMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/index.vue?macro=true";
import { default as indexSI3MAsDi7zMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/recertification/index.vue?macro=true";
import { default as indexpXFBX8bkIfMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/registrations/index.vue?macro=true";
import { default as index5KZygFQdMrMeta } from "/usr/local/jenkins/workspace/appvastgoedcertnl/pages/index.vue?macro=true";
export default [
  {
    name: "crm-brandguide",
    path: "/crm/brandguide",
    meta: indexZWT5guxd9lMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/brandguide/index.vue")
  },
  {
    name: "crm-certificates-add-relationId",
    path: "/crm/certificates/add/:relationId()",
    meta: _91relationId_93mch4nD2dcTMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/add/[relationId].vue")
  },
  {
    name: "crm-certificates-add",
    path: "/crm/certificates/add",
    meta: index48kfoKFxXcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/add/index.vue")
  },
  {
    name: "crm-certificates-edit-id",
    path: "/crm/certificates/edit/:id()",
    meta: _91id_934o4zXCgSZoMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/edit/[id].vue")
  },
  {
    name: "crm-certificates-expiring-this-year",
    path: "/crm/certificates/expiring-this-year",
    meta: indexoyXbQsvhO6Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/expiring-this-year/index.vue")
  },
  {
    name: "crm-certificates",
    path: "/crm/certificates",
    meta: indexkmeWoVPDaMMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/index.vue")
  },
  {
    name: "crm-certificates-prolongate",
    path: "/crm/certificates/prolongate",
    meta: indexzcbzLSFYVJMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/prolongate/index.vue")
  },
  {
    name: "crm-certificates-view-id",
    path: "/crm/certificates/view/:id()",
    meta: _91id_93Es0sEcra5PMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/certificates/view/[id].vue")
  },
  {
    name: "crm-chambers-add",
    path: "/crm/chambers/add",
    meta: indexnhscMWKI2AMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/add/index.vue")
  },
  {
    name: "crm-chambers-edit-id",
    path: "/crm/chambers/edit/:id()",
    meta: _91id_93bdPtxjnIrSMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/edit/[id].vue")
  },
  {
    name: "crm-chambers",
    path: "/crm/chambers",
    meta: indexkmBkBKdnbtMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/chambers/index.vue")
  },
  {
    name: "crm-companies-add",
    path: "/crm/companies/add",
    meta: indexFCGSgHWNERMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/add/index.vue")
  },
  {
    name: "crm-companies-edit-id",
    path: "/crm/companies/edit/:id()",
    meta: _91id_93rEsRgGfzu7Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/edit/[id].vue")
  },
  {
    name: "crm-companies",
    path: "/crm/companies",
    meta: indexVU2k53z6inMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/index.vue")
  },
  {
    name: "crm-companies-view-id",
    path: "/crm/companies/view/:id()",
    meta: _91id_93hrJcF4z1zcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/companies/view/[id].vue")
  },
  {
    name: "crm-courses-edit-id",
    path: "/crm/courses/edit/:id()",
    meta: _91id_931ukLx50lrWMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/edit/[id].vue")
  },
  {
    name: "crm-courses",
    path: "/crm/courses",
    meta: indexLNsgFIZEH8Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/index.vue")
  },
  {
    name: "crm-courses-view-id",
    path: "/crm/courses/view/:id()",
    meta: _91id_93ZTA4NrKmgdMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/courses/view/[id].vue")
  },
  {
    name: "crm-dashboard",
    path: "/crm/dashboard",
    meta: index0zn8SFgkZUMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/dashboard/index.vue")
  },
  {
    name: "crm-documents-add",
    path: "/crm/documents/add",
    meta: indexCT81Fw0wZXMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/add/index.vue")
  },
  {
    name: "crm-documents-edit-id",
    path: "/crm/documents/edit/:id()",
    meta: _91id_9379Bce3s7z2Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/edit/[id].vue")
  },
  {
    name: "crm-documents",
    path: "/crm/documents",
    meta: indexqEt7cZHdWKMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/documents/index.vue")
  },
  {
    name: "crm-exact",
    path: "/crm/exact",
    meta: indexuutEyE4BlUMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/exact/index.vue")
  },
  {
    name: "crm-extranet-notifications-add",
    path: "/crm/extranet-notifications/add",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/add/index.vue")
  },
  {
    name: "crm-extranet-notifications-edit-id",
    path: "/crm/extranet-notifications/edit/:id()",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/edit/[id].vue")
  },
  {
    name: "crm-extranet-notifications",
    path: "/crm/extranet-notifications",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/extranet-notifications/index.vue")
  },
  {
    name: "crm",
    path: "/crm",
    meta: indexn8hDdLg0PoMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/index.vue")
  },
  {
    name: "crm-invoices-status",
    path: "/crm/invoices/:status()",
    meta: _91status_93JwGuaFiyFoMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/[status].vue")
  },
  {
    name: "crm-invoices-add-relation",
    path: "/crm/invoices/add/:relation()",
    meta: _91relation_93uji7LxpzBXMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/add/[relation].vue")
  },
  {
    name: "crm-invoices-add",
    path: "/crm/invoices/add",
    meta: indexm9ge6HjeFfMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/add/index.vue")
  },
  {
    name: "crm-invoices-annual",
    path: "/crm/invoices/annual",
    meta: indexMb4k6KvCAeMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/annual/index.vue")
  },
  {
    name: "crm-invoices-edit-id",
    path: "/crm/invoices/edit/:id()",
    meta: _91id_930vghZBBMEsMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/edit/[id].vue")
  },
  {
    name: "crm-invoices",
    path: "/crm/invoices",
    meta: indexrQNlTh0qS0Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/index.vue")
  },
  {
    name: "crm-invoices-send-status",
    path: "/crm/invoices/send/:status()",
    meta: _91status_93GoZG5TnNbYMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/send/[status].vue")
  },
  {
    name: "crm-invoices-view-id",
    path: "/crm/invoices/view/:id()",
    meta: _91id_93ifvUNNPB5vMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/invoices/view/[id].vue")
  },
  {
    name: "crm-level-of-measurements-type",
    path: "/crm/level-of-measurements/:type()",
    meta: _91type_93QAcNrZs9X9Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/[type].vue")
  },
  {
    name: "crm-level-of-measurements-status-texts-edit-id",
    path: "/crm/level-of-measurements/status-texts/edit/:id()",
    meta: _91id_93d1bvJdgHohMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/status-texts/edit/[id].vue")
  },
  {
    name: "crm-level-of-measurements-status-texts",
    path: "/crm/level-of-measurements/status-texts",
    meta: index6BM5GApEPwMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/status-texts/index.vue")
  },
  {
    name: "crm-level-of-measurements-view-id-type",
    path: "/crm/level-of-measurements/view/:id()/:type()",
    meta: _91type_933P2hza203JMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/level-of-measurements/view/[id]/[type].vue")
  },
  {
    name: "crm-login-forgot-password",
    path: "/crm/login/forgot-password",
    meta: indexhMpTzqeN6hMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/forgot-password/index.vue")
  },
  {
    name: "crm-login-forgot-password-reset-token",
    path: "/crm/login/forgot-password/reset/:token()",
    meta: _91token_93hdTMI7YQcaMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/forgot-password/reset/[token].vue")
  },
  {
    name: "crm-login",
    path: "/crm/login",
    meta: indexeajxvNVXr9Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/login/index.vue")
  },
  {
    name: "crm-mail-messages-edit-id",
    path: "/crm/mail-messages/edit/:id()",
    meta: _91id_93koKpwxAPfEMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/mail-messages/edit/[id].vue")
  },
  {
    name: "crm-mail-messages",
    path: "/crm/mail-messages",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/mail-messages/index.vue")
  },
  {
    name: "crm-products-add",
    path: "/crm/products/add",
    meta: indexf5bucg1sx3Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/add/index.vue")
  },
  {
    name: "crm-products-edit-id",
    path: "/crm/products/edit/:id()",
    meta: _91id_93stQcPd9JN5Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/edit/[id].vue")
  },
  {
    name: "crm-products",
    path: "/crm/products",
    meta: indexdDzPEtEXLjMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/products/index.vue")
  },
  {
    name: "crm-registration-types-add",
    path: "/crm/registration-types/add",
    meta: indexYDBZWTgwg1Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/add/index.vue")
  },
  {
    name: "crm-registration-types-edit-id",
    path: "/crm/registration-types/edit/:id()",
    meta: _91id_93JZmPtQkfGtMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/edit/[id].vue")
  },
  {
    name: "crm-registration-types",
    path: "/crm/registration-types",
    meta: indexEV9liVIPU2Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registration-types/index.vue")
  },
  {
    name: "crm-registrations",
    path: "/crm/registrations",
    meta: indexkQrIcOecfAMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/registrations/index.vue")
  },
  {
    name: "crm-relations-add-course-id",
    path: "/crm/relations/add-course/:id()",
    meta: _91id_93wtRuw8U8qLMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/add-course/[id].vue")
  },
  {
    name: "crm-relations-add",
    path: "/crm/relations/add",
    meta: indexEYL4TD5YrLMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/add/index.vue")
  },
  {
    name: "crm-relations-edit-id",
    path: "/crm/relations/edit/:id()",
    meta: _91id_93DHWuVEbnLsMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/edit/[id].vue")
  },
  {
    name: "crm-relations",
    path: "/crm/relations",
    meta: index1nwG20phuiMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/index.vue")
  },
  {
    name: "crm-relations-view-id",
    path: "/crm/relations/view/:id()",
    meta: indextsIgrh2ATnMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/view/[id]/index.vue")
  },
  {
    name: "crm-relations-view-id-level-measurements-levelMeasurementId",
    path: "/crm/relations/view/:id()/level-measurements/:levelMeasurementId()",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/relations/view/[id]/level-measurements/[levelMeasurementId].vue")
  },
  {
    name: "crm-reports-create",
    path: "/crm/reports/create",
    meta: indexepKP2x6CDEMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/reports/create/index.vue")
  },
  {
    name: "crm-reports",
    path: "/crm/reports",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/reports/index.vue")
  },
  {
    name: "crm-signups-edit-id",
    path: "/crm/signups/edit/:id()",
    meta: _91id_93XuOYFebkELMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/edit/[id].vue")
  },
  {
    name: "crm-signups",
    path: "/crm/signups",
    meta: indexn765xSOa3PMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/index.vue")
  },
  {
    name: "crm-signups-view-id",
    path: "/crm/signups/view/:id()",
    meta: _91id_93id3JbssjBIMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/signups/view/[id].vue")
  },
  {
    name: "crm-subscriptions-add-relationId",
    path: "/crm/subscriptions/add/:relationId()",
    meta: _91relationId_93yTov4kTL7uMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/add/[relationId].vue")
  },
  {
    name: "crm-subscriptions-add",
    path: "/crm/subscriptions/add",
    meta: indexi3whM4F5hQMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/add/index.vue")
  },
  {
    name: "crm-subscriptions-edit-subscriptionId",
    path: "/crm/subscriptions/edit/:subscriptionId()",
    meta: _91subscriptionId_93KTisgdu4X9Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/edit/[subscriptionId].vue")
  },
  {
    name: "crm-subscriptions-view-subscriptionId",
    path: "/crm/subscriptions/view/:subscriptionId()",
    meta: _91subscriptionId_93SnctBhgc3YMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/subscriptions/view/[subscriptionId].vue")
  },
  {
    name: "crm-tax-rates-add",
    path: "/crm/tax-rates/add",
    meta: indexy8lGmqUYEdMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/add/index.vue")
  },
  {
    name: "crm-tax-rates-edit-id",
    path: "/crm/tax-rates/edit/:id()",
    meta: _91id_9372yGuBbADUMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/edit/[id].vue")
  },
  {
    name: "crm-tax-rates",
    path: "/crm/tax-rates",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/tax-rates/index.vue")
  },
  {
    name: "crm-users-add",
    path: "/crm/users/add",
    meta: indexluYsXIpK80Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/add/index.vue")
  },
  {
    name: "crm-users-edit-id",
    path: "/crm/users/edit/:id()",
    meta: _91id_93Wb2MrIXMMXMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/edit/[id].vue")
  },
  {
    name: "crm-users",
    path: "/crm/users",
    meta: indexx0gm6fyJ93Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/crm/users/index.vue")
  },
  {
    name: "extranet-bedankt",
    path: "/extranet/bedankt",
    meta: indexqH4HVTJAqQMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/bedankt/index.vue")
  },
  {
    name: "extranet-certificates",
    path: "/extranet/certificates",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/certificates/index.vue")
  },
  {
    name: "extranet-courses",
    path: "/extranet/courses",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/courses/index.vue")
  },
  {
    name: "extranet-documents",
    path: "/extranet/documents",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/documents/index.vue")
  },
  {
    name: "extranet-further-training",
    path: "/extranet/further-training",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/further-training/index.vue")
  },
  {
    name: "extranet",
    path: "/extranet",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/index.vue")
  },
  {
    name: "extranet-inschrijven-bevestiging",
    path: "/extranet/inschrijven/bevestiging",
    meta: bevestigingdXSsaTawYcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/bevestiging.vue")
  },
  {
    name: "extranet-inschrijven-formulier",
    path: "/extranet/inschrijven/formulier",
    meta: formulierySCU0eAbyzMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/formulier.vue")
  },
  {
    name: "extranet-inschrijven",
    path: "/extranet/inschrijven",
    meta: indexfwzwj55ZxwMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/inschrijven/index.vue")
  },
  {
    name: "extranet-level-measurements",
    path: "/extranet/level-measurements",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/level-measurements/index.vue")
  },
  {
    name: "extranet-login-slug",
    path: "/extranet/login/:slug(.*)*",
    meta: _91_46_46_46slug_93nlh0KHqievMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/[...slug].vue")
  },
  {
    name: "extranet-login-as",
    path: "/extranet/login/as",
    meta: asuKbX6Q6NTKMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/as.vue")
  },
  {
    name: "extranet-login-forgot-password",
    path: "/extranet/login/forgot-password",
    meta: index6cGWjbFdmYMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/forgot-password/index.vue")
  },
  {
    name: "extranet-login-forgot-password-reset-token",
    path: "/extranet/login/forgot-password/reset/:token()",
    meta: _91token_93Otb2XpWusBMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/login/forgot-password/reset/[token].vue")
  },
  {
    name: "extranet-my-invoices",
    path: "/extranet/my-invoices",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/my-invoices/index.vue")
  },
  {
    name: "extranet-opleiding",
    path: "/extranet/opleiding",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/opleiding/index.vue")
  },
  {
    name: "extranet-profile-certificate-id",
    path: "/extranet/profile/certificate/:id()",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/certificate/[id].vue")
  },
  {
    name: "extranet-profile-edit-change-password",
    path: "/extranet/profile/edit/change-password",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/edit/change-password/index.vue")
  },
  {
    name: "extranet-profile-edit",
    path: "/extranet/profile/edit",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/edit/index.vue")
  },
  {
    name: "extranet-profile",
    path: "/extranet/profile",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/profile/index.vue")
  },
  {
    name: "extranet-recertification",
    path: "/extranet/recertification",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/recertification/index.vue")
  },
  {
    name: "extranet-registrations",
    path: "/extranet/registrations",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/extranet/registrations/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/jenkins/workspace/appvastgoedcertnl/pages/index.vue")
  }
]