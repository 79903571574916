import { BaseFactory } from './BaseFactory';
import { CertificateStateFactory } from './CertificateStateFactory';
import { ChamberFactory } from './ChamberFactory';
import { RelationFactory } from './RelationFactory';
import { AttachmentFactory } from './AttachmentFactory';
import { SubscriptionPeriodFactory } from './SubscriptionPeriodFactory';
import { Certificate } from '~/models/Certificate';
import type { ReadCertificateDto } from '~/types/Certificate';
import { RemarkFactory } from '~/models/factories/RemarkFactory';

export class CertificateFactory extends BaseFactory<ReadCertificateDto, Certificate> {
    /* eslint-disable-next-line max-statements, complexity */
    public toModel(dto: ReadCertificateDto): Certificate {
        const model = new Certificate();

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        model.certificateNumber = dto.certificateNumber;

        if (dto.certificateState) {
            model.certificateState = (new CertificateStateFactory()).toModel(dto.certificateState.data);
        }

        if (dto.chamber) {
            model.chamber = (new ChamberFactory()).toModel(dto.chamber.data);
        }

        model.chamberCode = dto.chamberCode || null;
        model.endDate = dto.endDate;
        model.fullStatus = dto.fullStatus || null;
        model.id = Number(dto.id);
        model.issuer = dto.issuer || null;
        model.issuerStatus = dto.issuerStatus || null;
        model.pointsAchieved = Number(dto.pointsAchieved);
        model.pointsRequired = Number(dto.pointsRequired);
        model.type = dto.type;
        model.periods = dto.periods ? (new SubscriptionPeriodFactory()).toModels(dto.periods.data) : null;
        model.remarks = dto.remarks ? (new RemarkFactory()).toModels(dto.remarks.data) : [];

        if (dto.relation) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        model.registrationRoute = dto.registrationRoute || null;
        model.relationName = dto.relationName || null;
        model.showPoints = dto.showPoints;
        model.startDate = dto.startDate;
        model.status = dto.status || null;

        return model;
    }
}
